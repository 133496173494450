





























import { UserProfileStore } from '@/stores/userProfileStore'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class UserCard extends Vue {
  @Prop({}) profile!: UserProfileStore
}
